<template>
  <div>
    <div v-if="resultList && resultList.length > 0">
      <div class="tsd-panel dataentrydiv">
        <datatable :columns="dataTableColumns" :data="resultList"></datatable>
      </div>
    </div>
    <div v-else>
      <div class="dataentrydiv">
        {{ $t('dashboardPage_noResults') }}
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'VaccinationTable',

  props: {
    resultListProp: Array,
  },

  data() {
    return {
      dataTableColumns: [
        { label: 'EMPLOYEE NAME', field: 'name' },
        {
          label: 'EMPLOYEE ID', field: 'id', class: 'phone', headerClass: 'phone',
        },
        { label: 'MOST RECENT TEST DATE', field: 'summaryDate' },
        {
          label: 'Status',
          representedAs: ({ summaryStatus, summaryDescription }) => {
            let badgeHTML = '';
            let badge = '';
            if (summaryStatus === 'OK') {
              badge = 'success';
            } else if (summaryStatus === 'WARN') {
              badge = 'warn';
            } else if (summaryStatus === 'NOT') {
              badge = 'critical';
            }

            badgeHTML = `<span class="badge ${badge}">${summaryDescription}</span>`;
            return `<div class="badge-container">${badgeHTML}</div>`;
          },
          interpolate: true,
        },
      ],
    };
  },

  computed: {
    resultList() {
      return [...this.resultListProp];
    },
  },

};
</script>
