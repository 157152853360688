import HttpClient from '@/api/http-client';

function getEndpoint() {
  return '/api/v1/dashboard';
}

const getRestaurantList = (employeeId) => {
  const endpoint = getEndpoint();
  const path = `${endpoint}/${encodeURIComponent(employeeId)}/restaurant`;
  return HttpClient.get(path);
};

const getEmployeeVaccinationByRestaurant = (employeeId, restaurantId) => {
  const endpoint = getEndpoint();
  const path = `${endpoint}/${encodeURIComponent(employeeId)}/restaurant/${encodeURIComponent(restaurantId)}/vaccination`;
  return HttpClient.get(path);
};

const getEmployeeCovidTestByRestaurant = (employeeId, restaurantId) => {
  const endpoint = getEndpoint();
  const path = `${endpoint}/${encodeURIComponent(employeeId)}/restaurant/${encodeURIComponent(restaurantId)}/covid-test`;
  return HttpClient.get(path);
};

const getEmployeeSummaryByRestaurant = (employeeId, restaurantId) => {
  const endpoint = getEndpoint();
  const path = `${endpoint}/${encodeURIComponent(employeeId)}/restaurant/${encodeURIComponent(restaurantId)}/summary`;
  return HttpClient.get(path);
};

export default {
  getRestaurantList,
  getEmployeeVaccinationByRestaurant,
  getEmployeeCovidTestByRestaurant,
  getEmployeeSummaryByRestaurant,
};
