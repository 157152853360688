<template>
  <div v-show="loaded" class="page-block-item">
    <div class="dataentrydiv restaurant-selection">
      <div class="daterow">
        <multiselect v-model="selectedRestaurant" :options="restaurantOptions"
            label="label" track-by="label"
            :searchable="true" :allow-empty="false" :close-on-select="true"
            :show-labels="false"
            :placeholder= "getSelectRestaurantLabel()">
        </multiselect>
        <multiselect v-model="selectedEmployee" :options="employeesOptions || []"
          label="label" track-by="label"
          :searchable="true" :allow-empty="false" :close-on-select="true"
          :show-labels="false"
          :placeholder="$t('dashboardPage_selectEmployeeLabel')">
        </multiselect>
      </div>
    </div>
  </div>
</template>

<script>
import DashboardApi from '@/api/dashboard.api';
import Utils from '@/utils/utils';

import Multiselect from 'vue-multiselect';

const CLIENT = Utils.getClient();

function mapToSelect(label, value) {
  return { label, value };
}

export default {
  name: 'SelectableRestaurant',
  components: {
    Multiselect,
  },
  props: ['value', 'userId', 'employeesOptions'],

  data() {
    return {
      loaded: false,
      restaurants: [],
      restaurantOptions: [],
      selectedRestaurant: undefined,
      selectedEmployee: undefined,
    };
  },

  async created() {
    try {
      const restaurantsResult = await DashboardApi.getRestaurantList(this.userId);
      this.restaurants = restaurantsResult.data;
      this.restaurantOptions = this.restaurants
        .map((restaurant) => mapToSelect(`${restaurant.name}`, restaurant.number));
      if (this.restaurantOptions.length === 1) {
        [this.selectedRestaurant] = this.restaurantOptions;
      }

      this.$emit('completed', true);
      this.$emit('restaurants', this.restaurants);
    } catch (e) {
      //
    }
    this.loaded = true;
  },

  methods: {
    getSelectRestaurantLabel() {
      if (CLIENT === 'sodexo') {
        return this.$t('selectRestaurantLabelSodexo');
      }
      return this.$t('selectLocationLabel');
    },
  },

  watch: {
    selectedRestaurant() {
      this.selectedEmployee = undefined;
      this.$emit('input', this.selectedRestaurant);
    },

    selectedEmployee() {
      this.$emit('selectedEmployee', this.selectedEmployee);
    },
  },
};
</script>
<style scoped>

</style>
