<template>
  <div class="page-block-item">
    <div>
      <span @click="logout" class="button logoutbutton w-inline-block underline-link"
        v-if="!isClientAuthTypeSSO">
        <img src="@/assets/images/logout-1.svg" loading="lazy" alt="" class="logouticon">
        <div>{{ $t('dashboardPage_logout') }}</div>
      </span>
    </div>

    <div>
      <div class="group-logo">
        <div><img class="client-logo" :src="getClientLogo()"></div>
      </div>

      <p class="page-title">{{ title }}</p>
    </div>
  </div>
</template>

<script>
import AuthApi from '@/api/auth.api';
import Utils from '@/utils/utils';

const CLIENT = Utils.getClient();

export default {
  name: 'PageHeaderDashboard',
  props: ['title', 'clientAuthType'],

  methods: {
    getClientLogo() {
      return `/vaccine/images/logo/${CLIENT}.png`;
    },

    async logout() {
      try {
        await AuthApi.logOut();
      } catch (e) {
        //
      }
    },
  },

  computed: {
    isClientAuthTypeSSO() {
      return this.clientAuthType === 'SSO';
    },
  },
};
</script>

<style scoped>

  .logoutbutton {
    width: 190px;
    color: #5031D0;
    text-decoration: none;
    position: absolute;
    right: 5%;
    margin-top: -25px;
    text-transform: uppercase;
  }

  .logouticon {
    width: 25px;
    margin-right: 12px;
  }

  .group-logo {
    display: block;
    text-align: center;
    margin-bottom: 75px;
  }

  .group-logo .client-logo {
    width: 150px;
    height: auto;
  }

  @media screen and (max-width: 479px) {

    .group-logo {
      margin-bottom: 50px;
    }

    .logoutbutton {
      position: relative;
      right: 0px;
      margin-top: -10px;
      margin-bottom: 20px;
      width: 100%;
    }
  }
</style>
