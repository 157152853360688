<template>
  <div>
    <div v-if="resultList && resultList.length > 0">
      <div class="tsd-panel dataentrydiv">
        <datatable :columns="dataTableColumns" :data="resultList"></datatable>
      </div>
    </div>
    <div v-else>
      <div class="dataentrydiv">
        {{ $t('dashboardPage_noResults') }}
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'CovidTestTable',

  props: {
    resultListProp: Array,
  },

  data() {
    return {
      dataTableColumns: [
        { label: 'Employee Name', field: 'name' },
        {
          label: 'Employee Id', field: 'id', class: 'phone', headerClass: 'phone',
        },
        { label: 'Covid Test Date', field: 'testDate' },
        {
          label: 'Current Test Status',
          representedAs: ({ isValid, testResult }) => {
            let badgeHTML = '';
            if (testResult === 'POSITIVE') {
              badgeHTML = '<span class="badge critical">COVID POSITIVE</span>';
            } else if (isValid) {
              badgeHTML = '<span class="badge success">VALID</span>';
            } else {
              badgeHTML = '<span class="badge warn">TEST TOO LONG AGO</span>';
            }

            return `<div class="badge-container">${badgeHTML}</div>`;
          },
          interpolate: true,
        },
      ],
    };
  },

  computed: {
    resultList() {
      return [...this.resultListProp];
    },
  },

};
</script>
