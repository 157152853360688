<template>
  <div class="page-block" v-if="loaded && !isZedicDashboard">
    <div class="banner" v-if="!isCovidTestView">
      {{ $t('dashboardPage_banner') }}
      <a href="https://www.zerohourhealth.com/blog/understanding-your-vaccination-status-dashboard" target="_blank">
        {{ $t('dashboardPage_bannerLink') }}
      </a>
    </div>
    <i18n-selector />

    <PageHeaderDashboard
      title="Store Dashboard"
      :clientAuthType="clientAuthType" />

    <MenuNavigation
      :loggedUser="loggedUser"
      :wellnessCheckURL="getWellnessCheckURL"
      :page="'MainDashboardPage'"/>

    <div class="page-block-item margin-bottom-15">
      <div class="dataentrydiv nav-buttons">
        <div class="daterow daterow-dashboard-nav-buttons">
          <span v-if="hasSummaryView"
            class="button w-button dashboard-nav-button dashboard-nav-menu-button"
            :class="{ active: isSummaryView }"
            @click="currentView = 'SUMMARY'">
            All Employee Statuses
          </span>
          <span v-if="hasVaccinationView"
            class="button w-button dashboard-nav-button dashboard-nav-menu-button"
            :class="{ active: isVaccinationView }"
            @click="currentView = 'VACCINATION'">
            Vaccination Tracking
          </span>
          <span v-if="hasCovidTestView"
            class="button w-button dashboard-nav-button dashboard-nav-menu-button"
            :class="{ active: isCovidTestView }"
            @click="currentView = 'COVID_TEST'">
            Covid Testing
          </span>
        </div>
      </div>
    </div>

    <SelectableRestaurant
      v-if="loggedUser && currentView"
      v-model="selectedRestaurant"
      :employeesOptions="employeesOptions"
      :userId="loggedUser.id"
      @completed="selectableRestaurantLoaded = $event"
      @selectedEmployee="selectedEmployee = $event"
      @restaurants="restaurants = $event" />

    <spinning-loader v-if="loadingPage || loadingRestaurantData"/>

    <div class="page-block-item" v-if="selectableRestaurantLoaded && !loadingRestaurantData">
      <CovidTestTable v-if="isCovidTestView"
        :resultListProp="filteredByEmployee" />

      <VaccinationTable v-if="isVaccinationView"
        :resultListProp="filteredByEmployee" />

      <SummaryTable v-if="isSummaryView"
        :resultListProp="filteredByEmployee" />

    </div>
    <div class="dataentrydiv">
      <privacy-policy />
    </div>
  </div>

</template>

<script>
import AuthApi from '@/api/auth.api';
import DashboardApi from '@/api/dashboard.api';
import Utils from '@/utils/utils';

import PageHeaderDashboard from '@/components/common/PageHeaderDashboard.vue';
import MenuNavigation from '@/components/common/user/MenuNavigation.vue';
import SelectableRestaurant from '@/components/dashboard/SelectableRestaurant.vue';
import CovidTestTable from '@/components/dashboard/CovidTestTable.vue';
import VaccinationTable from '@/components/dashboard/VaccinationTable.vue';
import SummaryTable from '@/components/dashboard/SummaryTable.vue';

const CLIENT = Utils.getClient();

function mapToSelect(label, value) {
  return { label, value };
}

export default {
  name: 'MainDashboardPage',
  components: {
    PageHeaderDashboard,
    MenuNavigation,
    SelectableRestaurant,
    CovidTestTable,
    VaccinationTable,
    SummaryTable,
  },

  data() {
    return {
      loaded: false,
      loadingPage: true,
      loadingRestaurantData: false,
      selectableRestaurantLoaded: false,
      loggedUser: undefined,
      clientAuthType: undefined,
      selectedRestaurant: undefined,
      selectedEmployee: undefined,
      employeesOptions: [],
      currentView: '',
      employeeResultList: [],
      features: [],
      restaurants: [],
    };
  },

  created() {
    this.fetchLoggedUser();
  },

  methods: {
    async fetchLoggedUser() {
      try {
        const loggedUserResult = await AuthApi.getMe();
        this.loggedUser = loggedUserResult.data;

        if (!this.loggedUser.roles?.includes('MANAGER')) {
          this.$router.push({ name: 'AuthErrorPage' });
          return;
        }

        this.clientAuthType = this.loggedUser.auth;
        this.features = [...this.loggedUser.features];

        this.loaded = true;

        if (this.isZedicDashboard) {
          window.location.href = Utils.getDashboardURL();
        }

        if (this.hasSummaryView) {
          this.currentView = 'SUMMARY';
        } else if (this.hasVaccinationView) {
          this.currentView = 'VACCINATION';
        } else if (this.hasCovidTestView) {
          this.currentView = 'COVID_TEST';
        }

        this.loadingPage = false;
      } catch (e) {
        console.error(e);
        this.showErroAlert({
          title: 'Oops!',
          text: this.$t('somethingWentWrong'),
        });
        this.loadingPage = false;
      }
    },

    async fetchRestaurantData() {
      this.loadingRestaurantData = true;
      try {
        let employeesResult = [];
        this.employeeResultList = [];

        if (!this.selectedRestaurant) {
          this.loadingRestaurantData = false;
          return;
        }

        if (this.isSummaryView) {
          employeesResult = await DashboardApi.getEmployeeSummaryByRestaurant(
            this.loggedUser.id, this.selectedRestaurant.value,
          );
        } else if (this.isVaccinationView) {
          employeesResult = await DashboardApi.getEmployeeVaccinationByRestaurant(
            this.loggedUser.id, this.selectedRestaurant.value,
          );
        } else if (this.isCovidTestView) {
          employeesResult = await DashboardApi.getEmployeeCovidTestByRestaurant(
            this.loggedUser.id, this.selectedRestaurant.value,
          );
        } else {
          this.loadingRestaurantData = false;
          return;
        }

        this.employeeResultList = employeesResult.data;
        this.employeesOptions = this.employeeResultList
          .map((el) => ({ value: el.id, label: el.name }))
          .filter((thing, index, self) => index === self.findIndex((t) => (
            t.value === thing.value
          )));
        this.employeesOptions.unshift(mapToSelect('All employees', undefined));

        this.loadingRestaurantData = false;
      } catch (e) {
        console.error(e);
        this.loadingRestaurantData = false;
        this.showErroAlert({
          title: 'Oops!',
          text: this.$t('somethingWentWrong'),
        });
      }
    },

    showErroAlert(message) {
      const options = {
        ...message,
        // position: 'top-end',
      };
      return this.$swal(options);
    },

    getRestaurantIdbyNumber(storeNum) {
      return this.restaurants.find((rest) => storeNum === rest.number).id;
    },
  },

  computed: {
    filteredByEmployee() {
      const selectedEmployee = { ...this.selectedEmployee };
      if (selectedEmployee && selectedEmployee.value) {
        return this.employeeResultList.filter((test) => (
          test.id === selectedEmployee.value
        ));
      }
      return this.employeeResultList;
    },

    isSummaryView() {
      return this.currentView === 'SUMMARY';
    },

    isVaccinationView() {
      return this.currentView === 'VACCINATION';
    },

    isCovidTestView() {
      return this.currentView === 'COVID_TEST';
    },

    hasSummaryView() {
      return this.hasVaccinationView && this.hasCovidTestView;
    },

    hasVaccinationView() {
      return true;
    },

    hasCovidTestView() {
      return this.features.includes('COVID_TEST_TRACKING');
    },

    isZedicDashboard() {
      return this.features.includes('ZEDIC_DASHBOARD');
    },

    getWellnessCheckURL() {
      if (!this.loggedUser) return '';
      let link = this.loggedUser.wellnessLink;
      if (this.selectedRestaurant?.value
          && this.clientAuthType !== 'SSO'
          && CLIENT !== 'citybbq'
          && CLIENT !== 'pappas'
          && CLIENT !== 'chwinery'
          && CLIENT !== 'thekitchen'
          && CLIENT !== 'loumalnatis') {
        link = `${link}&storeemail=${this.getRestaurantIdbyNumber(this.selectedRestaurant.value)}`;
      }
      return link;
    },
  },

  watch: {
    selectedRestaurant() {
      this.fetchRestaurantData();
    },

    currentView() {
      this.fetchRestaurantData();
    },
  },

};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
  .spinning-loader {
    margin-top: 20px;
  }
  /* Data table */
  .tsd-panel table {
    width: 100%;
  }

  .tsd-panel table thead {
    color: #818181;
    font-weight: 600;
    text-transform: uppercase;
  }

  .tsd-panel table tr td {
    height: 50px;
    text-align: center !important;;
    white-space: nowrap;
  }

  .tsd-panel table tr:nth-child(2n) {
    background-color: #ececec;
  }

  .badge-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .badge {
    font-weight: 600;

    /* Center the content */
    align-items: center;
    display: flex;
    justify-content: center;

    /* Rounded border */
    border-radius: 9999px;
    height: 32px;
    width: fit-content;
    padding-left: 10px;
    padding-right: 10px;
  }

  .badge.unknown {
    background-color: rgba(200, 200, 200);
  }

  .badge.success {
    background-color: #7AF0B0;
    color: #268350;
  }

  .badge.warn {
    background-color: #F3DF27;
    color: #928514;
  }

  .badge.critical {
    background-color: #EE6666;
    color: #FFEDED;
  }

  .nav-buttons {
    padding-top: 0px !important;
  }

  .daterow {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    max-width: 800px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .daterow-dashboard-nav-buttons {
    justify-content: center;
    flex-wrap: wrap;
    border: 1px solid #aaadb0;
    border-radius: 8px;
    width: 600px;
  }

  .dashboard-nav-menu-button {
    background-color: #ffffff;
    border: 0px;
    color: #aaadb0;
    border-radius: 8px;
  }

  .restaurant-selection {
    margin-top: -20px;
  }

  .restaurant-selection > .daterow > .multiselect {
    margin: 0 auto;
  }

  .restaurant-selection > .daterow > .multiselect:first-child {
    margin-right: 7px;
  }

  .restaurant-selection > .daterow > .multiselect:last-child {
    margin-left: 7px;
  }

  .dashboard-nav-button {
    min-height: 60px;
    height: 60px;
    flex: 1 0 21%;
  }

  .dashboard-nav-menu-button:hover {
    color: #757575;
  }

  .active, .active:hover {
    background-color: #ececec;
  }

  .banner {
    position: relative;
    top: -50px;
    background-color: #FFC7B6;
    width: 100%;
    text-align: center;
    padding: 15px;
  }

  .banner a, .banner a:active {
    font-weight: bold;
    text-decoration: none;
    color: black;
  }

  @media screen and (max-width: 479px) {
    .daterow {
      width: 100%;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }

    .phone {
      display: none;
    }

    .dashboard-nav-menu-button {
      margin-top: 0px !important;
    }

    .dashboard-nav-button {
      width: 100%;
      max-width: 100%;
      margin: 0;
    }

    .restaurant-selection > .daterow > .multiselect:first-child,
    .restaurant-selection > .daterow > .multiselect:last-child {
      margin: 7px 0px;
    }

    .banner {
      width: calc(100% + 30px);
    }

    .badge {
      display: flex;
      max-width: 150px;
      height: 44px;
      white-space: pre-wrap;
      font-size: 11px;
      line-height: 10px;
      flex-direction: column;
    }
  }
</style>
